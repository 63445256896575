//import _                        from 'lodash';
import React, { useContext }    from 'react';
import { Logger }               from 'aws-amplify';
import { makeStyles }           from '@material-ui/core/styles';
import Card                     from '@material-ui/core/Card';
import CardActionArea           from '@material-ui/core/CardActionArea';
import CardActions              from '@material-ui/core/CardActions';
import CardContent              from '@material-ui/core/CardContent';
import CardMedia                from '@material-ui/core/CardMedia';
//import ListItem                 from '@material-ui/core/ListItem';
//import Link                     from '@material-ui/core/Link';
import Box                      from '@material-ui/core/Box';
import Button                   from '@material-ui/core/Button';
import Typography               from '@material-ui/core/Typography';
import Tooltip                  from '@material-ui/core/Tooltip';

import IconButtonNewWindow      from 'pac-responsive-ui-framework/core/IconButtonNewWindow';

import IconButtonFavorite       from './IconButtonFavorite';
import IconButtonConfluence     from './IconButtonConfluence';
import { useHistory }           from "react-router-dom";
import { parseHTMLtoJSX }       from 'pac-responsive-ui-framework/core/HelperHTML';

import { constants }            from 'assets/Config.js';
import AppContext               from 'pac-responsive-ui-framework/core/AppContext';
import { getURLParams }         from 'data/ReportList';

const logger = new Logger('CardReport');

const useStyles = makeStyles(theme => ({
  card: {
   //maxWidth: 260,
   //minWidth:260,
    height: "100%",
    borderWidth: 2
  },
  media: {
    height: 140,
  },
  media_small: {
    height: 54,
    width: 120
  },
  content_small: {
     height: 80,
  },
  content: {
    height: 160
    // [theme.breakpoints.only('xl')]: {
     //  height: '6vw'
    // },

    // [theme.breakpoints.only('lg')]: {
    //  height: '12vw'
  //   },
  //   [theme.breakpoints.between('sm', 'md')]: {
    //  height: '18vw'
  //   }
  },
  actions: {
    height: 40
  },
  tags: {
    fontSize: 11
  }
}));

export default function CardReport(props) {
  
  logger.debug("props", props);
  var app_context = useContext(AppContext);
  const classes = useStyles();
  let history = useHistory();
  const handleClick = name => event => {
    logger.debug("handleClick", name);
    history.push(name);
  };
  
  var image = constants.assets_path + "thumb1.jpg";
  if (props.image) {
    try {
      image= constants.assets_path + props.image;
    }
    catch (e) {
      // stick with default image
    }
  }
  
  var customer_icon = "";
  if (props.customer_icon) {
    try {
      customer_icon= "https://origins-assets.nextcloud.aero/operator-logos/" + props.customer_icon;
    }
    catch (e) {
      // stick with default image
    }
  }

  /* // remove the info icon
  var icon_button_confluence = '';
  if (props.docs_href) {
     icon_button_confluence = 
            <IconButtonConfluence href={props.docs_href} />;

  }
  */
  // process the tags 
  var tags;
  if (props.size != "small") { 
    if (props.tags) {        
      tags = props.tags.map((item, key) =>  {
         //const tagsLink = () => <Link to={"/search" + item} />;
         
         return (
           
          <Button 
              component="button" 
              key ={key}
              className={classes.tags} 
              size="small" 
              color="primary" 
              style={{textTransform: 'none'}}
              onClick =  {(event) => {
                history.push('/search/' + event.target.innerText);
              }}
          >
            {item}
          </Button>
        );
      });
    }
    tags = <Box minHeight={62}>{tags}</Box>;
  }
  
  var report_environment_tags;
  var report_type;;
  if (process.env.REACT_APP_STAGE == 'dev' || process.env.REACT_APP_STAGE == 'test') {
    logger.debug("report_environments", props.report_environments);
    
   
    if (process.env.REACT_APP_STAGE == 'dev' || process.env.REACT_APP_STAGE == 'test') {
      report_type =  props.report_type;
    }
    
    
    var report_environments = props.report_environments;
    if(report_environments == null || report_environments === ""){
       report_environments = ['prod'];
    }
    //var report_environments = '';
    var environment_style = {
        'dev': {color:'#fff',background:'#cc3232'},
        'test': {color:'#fff',background:'#e7b416'},
        'prod': {color:'#fff',background:'#99c140'},
        'prd': {color:'#fff',background:'#99c140'}
    };
       
    report_environment_tags = report_environments.filter(function(item) {
        //if(process.env.REACT_APP_STAGE == 'test'){
          //if(item=='dev' && report_environments.includes('test')){return false;}
        //}      
        return true;
      }).map((item, key) =>  {
       
       return (
        <Button component="button" key ={key}
    
        variant="outlined"
            size="small" 
            color="primary" 
            className={item}
            style={{textTransform: 'none', padding: '0px!important', minWidth:'40px', lineHeight:'1.2em', margin:'2px', color: environment_style[item].color, backgroundColor: environment_style[item].background}}
            onClick =  {(event) => {
              history.push('/search/env:' + event.target.innerText);
            }}
        >
          {item}
        </Button>
      );
    });
  

  }
  
  var subtitles = '';
  if (props.showSubtitle == true) {
    subtitles = (
          <Typography variant="body2" color="textSecondary" component="p">
            {parseHTMLtoJSX(props.subtitle)}
          </Typography>
      );
  }
  
  var card_action_area;
  var icon_button_new_window;

  var card_media = 
     <CardMedia
        className={classes.media}
        image={image}
        title={props.title}
      />;
  

  if (props.size === "small") {
    var last_visited;
    if (props.last_visit_time) last_visited= <p><em>Last Visited:</em> <b>{props.last_visit_time}</b></p>;
    var tooltip = 
        <React.Fragment>
          <Typography color="inherit">{props.subtitle}</Typography>
          {last_visited}  
        </React.Fragment>;
    
    if (props.target === "_blank") {
      card_action_area =  
        <a href={props.href + getURLParams(app_context)} target={props.target} rel="noreferrer noopener" >
          <CardActionArea>
          <Tooltip title={tooltip}>
          <table ><tbody><tr><td >
          <CardMedia
            className={classes.media_small}
            image={image}
          />
          </td><td>
          <CardContent className={classes.content_small}>
            <Typography gutterBottom variant="body1" component="p" style={{fontSize: "14px",marginTop: 0}}>
              <img src={customer_icon} height="40" style={{float: 'right'}}/>
              <b>
              {props.title}
              </b>
            </Typography>
           
          </CardContent>
          </td></tr></tbody></table>
          </Tooltip>
          </CardActionArea>
        </a>;
      icon_button_new_window =
        <div title="External link.  This will open in a new tab or window" style={{paddingLeft: "20px", paddingRight:"18px"}}>↗</div>;
    }
    else {
      card_action_area =  <CardActionArea onClick={handleClick(props.href)} >
           <Tooltip title={tooltip}>
         <table title={tooltip}><tbody><tr><td>
          <CardMedia
            className={classes.media_small}
            image={image}
            
            
          />
          </td><td>
          <CardContent className={classes.content_small} >
            <Typography gutterBottom variant="body1" component="p" style={{fontSize: "14px",marginTop: 0}} > 
              <img src={customer_icon} height="40" style={{float: 'right'}}/>
              <b>
              {props.title}
              </b>
            </Typography>
           
          </CardContent>
          </td></tr></tbody></table>
          </Tooltip>
        </CardActionArea>;
   
        icon_button_new_window = <IconButtonNewWindow href={props.href + getURLParams(app_context)}  />;
    }
    
  }
  else {
    if (props.target === "_blank") {
    
      card_action_area =  
        <a href={props.href + getURLParams(app_context)} target={props.target} rel="noreferrer noopener">
          <CardActionArea>
            <CardMedia
              className={classes.media}
              image={image}
              title={props.title}
            />
            <CardContent className={classes.content}>
              <Typography gutterBottom variant="body1" component="p">
                <img src={customer_icon} height="40" style={{float: 'right'}}/>
                <b>
                {props.title}
                </b>
              </Typography>
             {subtitles}
            </CardContent>
          </CardActionArea>
        </a>;
      icon_button_new_window =
        <div title="External link.  This will open in a new tab or window" style={{paddingLeft: "20px", paddingRight:"18px"}}>↗</div>;
    }
    else {
      
      card_action_area =  <CardActionArea onClick={handleClick(props.href)}>
          <CardMedia
            className={classes.media}
            image={image}
            title={props.title}
          />
          <CardContent className={classes.content}>
            <Typography gutterBottom variant="body1" component="p">
              <img src={customer_icon} height="40" style={{float: 'right'}}/>
              <b>
              {props.title}
              </b>
            </Typography>
           {subtitles}
          </CardContent>
        </CardActionArea>;
   
        icon_button_new_window = <IconButtonNewWindow href={props.href + getURLParams(app_context)}  />;
    }
  }  

   
  return (
    <Card className={classes.card} square={true} variant="outlined">
      
      <Box minHeight={10} style={{float: 'left', paddingLeft: "6px", paddingTop: "4px"}}>
        {report_type}
      </Box>
      <Box minHeight={10} style={{float: 'right'}}>
        {report_environment_tags}
      </Box>
      {card_action_area}
      {tags}
      <CardActions className={classes.actions}>
        <IconButtonFavorite title={props.title} favorite={props.favorite}/>
        {icon_button_new_window}
      </CardActions>
    </Card>
  );
  
}
